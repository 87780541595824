import iziToast from 'izitoast';
/*添加cookie*/
export const setCookie = (name, value) => {
    var Days = 30;
    var exp = new Date();
    exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
    document.cookie = name + "=" + escape(value) + ";path=/;expires=" + exp.toGMTString();
};

/*读取cookie*/
export const getCookie = (name) => {
    var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    if (arr = document.cookie.match(reg))
        return unescape(arr[2]);
    else
        return null;
};

/*删除cookie*/
export const delCookie = (name) => {
    var exp = new Date();
    exp.setTime(exp.getTime() - 1);
    var cval = getCookie(name);
    if (cval != null)
        document.cookie = name + "=" + cval + ";path=/;expires=" + exp.toGMTString();
};


export const setCookieWithExp = (name, value, daysUntilExpire) => {
    var date = new Date();
    date.setTime(date.getTime() + (daysUntilExpire * 24 * 60 * 60 * 1000));
    var expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

//网页进入灰色模式
(function () {
    console.log(111222333)
    var date = new Date();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var dateStr = (month > 9 ? month : ('0' + month)) + '' + (day > 9 ? day : ('0' + day));
    switch (dateStr) {
        case '0404'://缅怀疫情罹难者
        case '0707'://警钟长鸣，勿忘国耻！
        case '0909':
        case '0918':
        case '1213':
            setTimeout(() => {
                iziToast.show({
                    'message': '今天是中国国家纪念日！'
                });
            }, 2000);
            var styleEl = document.createElement('style');
            styleEl.innerHTML = "html {\n" +
                "    -webkit-filter: grayscale(100%) !important;\n" +
                "    -moz-filter: grayscale(100%) !important;\n" +
                "    -ms-filter: grayscale(100%) !important;\n" +
                "    -o-filter: grayscale(100%) !important;\n" +
                "    filter: grayscale(100%) !important;\n" +
                "    filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1) !important;\n" +
                "}";
            var ref = document.querySelector('script');
            ref.parentNode.insertBefore(styleEl, ref);
            break;
    }
})();