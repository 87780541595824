<template>
  <div class="codics-factory-login-body">
    <div class="codics-factory-login-box">
      <div class="codics-factory-login-carousel-box">
        <el-carousel class="codics-factory-login-carousel" height="362px" width="100%">
          <el-carousel-item class="codics-factory-login-carousel-item" v-for="item in 1" :key="item">
            <el-image src="https://cdn.bosscaigou.com/%E7%AE%80%E5%8D%95%E6%90%9C%E7%B4%A2/img/banner2.svg"
              style="height: 100%;width: 100%;" fit="fill"></el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="codics-factory-login-view">
        <div class="codics-factory-login-title-box">
          <div class="codics-factory-login-title" v-for="(item, index) in ['密码登录']" :key="index"
            :style="{ '--idxState': idxState == index ? '1.5px solid rgba(255, 255, 255,.8)' : 'unset', '--fontCol': idxState == index ? '#FFF' : 'rgba(255, 255, 255, .4)' }"
            @click="idxState = index">{{ item }}</div>
        </div>
        <el-input class="codics-factory-login-input" v-model="logInfo.a" maxlength="22" placeholder="请输入账号/邮箱/手机号..."
          style="margin-bottom: 15px;" clearable></el-input>
        <el-input class="codics-factory-login-input" v-model="logInfo.p" maxlength="22" placeholder="请输入账号相应的密码..."
          :show-password="true" style="margin-bottom: 15px;" clearable></el-input>
        <el-input class="codics-factory-login-input codics-factory-login-verify-code" placeholder="请输入验证码" maxlength="4"
          v-model="logInfo.v" clearable>
          <template slot="append">
            <el-image class="codics-factory-login-input-card-code" :src="verifyImg" fit="fill" @click="changeVerifyFuc">
              <div slot="error" class="image-slot"
                style="height:fit-content;width: fit-content;position: absolute;margin: auto;top: 0;left: 0;right: 0;bottom: 0;"
                @click="changeVerifyFuc">
                <i class="el-icon-loading" v-show="verifyState"></i>
              </div>
            </el-image>
            <div
              style="height: 100%;width: 100%;position: absolute;line-height: 40px;text-align: center;font-size: 13px;left: 0;top: 0;user-select: none;cursor: pointer;"
              v-show="!verifyState" @click="changeVerifyFuc(); verifyState = true;">加载验证码</div>
          </template>
        </el-input>
        <div class="codics-factory-login-line">
          <el-checkbox v-model="logCheck" style="color: #FFF;">7天免登录</el-checkbox>
          <a class="codics-factory-login-line-pass" href="https://qm.qq.com/q/VPQBQGwUUI">忘记密码？</a>
        </div>
        <div class="codics-factory-login-input-card-login" @click="userLoginFuc">登录/注册</div>
      </div>
    </div>
  </div>
</template>
<script>
import iziToast from 'izitoast'
import { changeVerify, userLogin } from '@/api';
import { setCookieWithExp } from '@/utils/mycookie';
import { jumpTo } from '@/utils/myutils';
export default {
  data() {
    return {
      idxState: 0,
      logInfo: {
        a: '',
        p: '',
        v: '',
        e: 0
      },
      logCheck: false,
      verifyImg: '',
      verifyState: false,
      lunboData: [
        {
          img: 'https://cdn.bosscaigou.com/%E7%AE%80%E5%8D%95%E6%90%9C%E7%B4%A2/img/banner.svg'
        }
      ]
    }
  },
  mounted() {
    this.changeVerifyFuc();
  },
  methods: {
    async changeVerifyFuc() {
      let data = await changeVerify();
      if (data.code == 200) {
        this.verifyState = true;
        this.verifyImg = data.data;
      } else {
        this.$message.error(data.message);
      }
    },
    async userLoginFuc() {
      if (this.logInfo.a == '') {
        this.$notify({
          title: '警告',
          message: '填写的账号不能为空！',
          type: 'warning'
        });
        return;
      }
      if (this.logInfo.p == '') {
        this.$notify({
          title: '警告',
          message: '填写的密码不能为空！',
          type: 'warning'
        });
        return;
      }
      if (this.logInfo.v == '') {
        this.$notify({
          title: '警告',
          message: '填写的验证码不能为空！',
          type: 'warning'
        });
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.logInfo.e = this.logCheck ? 168 : 2;
      let data = await userLogin(this.logInfo);
      loading.close();
      if (data.code == 200) {
        this.$message({
          message: data.message,
          type: 'success'
        });
        setCookieWithExp("user-inf", JSON.stringify(data), this.logCheck ? 7 : (1 / 12));
        // 刷新仓库数据
        this.$store.dispatch('login');
        // 登录成功跳转
        jumpTo(this, '导航', {}, {});
      } else this.$message.error(data.message);
    }
  }
}
</script>
<style>
/* 登录界面 */
.codics-factory-login-body {
  height: 100%;
  width: 100%;
  position: relative;
  float: left;
}

/* 登录输入框容器 */
.codics-factory-login-box {
  height: fit-content;
  width: fit-content;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  border-radius: 8px;
  background: rgba(255, 255, 255, .25);
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, .18);
}

/* 轮播盒子 */
.codics-factory-login-carousel-box {
  height: 362px;
  width: 650px;
  position: relative;
  float: left;
  box-sizing: border-box !important;
  overflow: hidden !important;
}

.codics-factory-login-carousel {
  box-shadow: 0 0 16px 2px rgba(0, 0, 0, .18);
  -moz-box-shadow: 0 0 6px 2px rgba(0, 0, 0, .08);
  -webkit-box-shadow: 0 0 6px 2px rgba(0, 0, 0, .08);
}

.codics-factory-login-carousel-item {
  height: 100%;
  width: 100%;
}

.codics-factory-login-view {
  height: 362px;
  width: 380px;
  position: relative;
  float: left;
  box-sizing: border-box;
  padding: 25px;
  border-radius: 8px;
  border-left: 1px solid rgba(255, 255, 255, .18);
}

.codics-factory-login-title-box {
  height: 40px;
  width: 100%;
  position: relative;
  float: left;
  border-bottom: 1px solid #FFF;
  margin-bottom: 20px;
}

.codics-factory-login-title {
  height: 100%;
  width: fit-content;
  position: relative;
  float: left;
  line-height: 40px;
  text-align: center;
  padding: 0 10px;
  box-sizing: border-box;
  color: var(--fontCol);
  font-size: 14px;
  font-weight: 500;
  border-bottom: var(--idxState);
  cursor: pointer;
  user-select: none;
}

/* 验证码图片 */
.codics-factory-login-input-card-code {
  height: 25px;
  width: 62.5px;
  top: 2.5px;
  box-sizing: border-box;
  cursor: pointer;
  color: #333;
  font-size: 11px;
}

.codics-factory-login-verify-code .el-input-group__append {
  color: #FFF !important;
  padding: 0 10px;
  background: rgba(255, 255, 255, .25) !important;
  backdrop-filter: blur(5px);
  border: unset !important;
  border-top: 1px solid rgba(255, 255, 255, .18);
  border-right: 1px solid rgba(255, 255, 255, .18);
  border-bottom: 1px solid rgba(255, 255, 255, .18);
}

.codics-factory-login-input-card-login {
  width: 100%;
  height: 40px;
  position: relative;
  float: left;
  line-height: 40px;
  box-sizing: border-box;
  text-align: center;
  background: rgba(255, 255, 255, .25);
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, .18);
  border-radius: 2px;
  font-size: 14px;
  color: #FFF;
  cursor: pointer;
  transition: .3s;
}

.codics-factory-login-input-card-login:hover {
  background: rgba(0, 0, 0, .18);
}

input:-webkit-autofill {
  transition: background-color 10000s ease-in-out 0s !important;
}

.codics-factory-login-line {
  height: 55px;
  width: 100%;
  position: relative;
  float: left;
  line-height: 55px;
}

.codics-factory-login-line-pass {
  height: 100%;
  width: fit-content;
  position: relative;
  float: right;
  line-height: 55px;
  font-size: 13px;
  color: #FFF;
  text-decoration: none;
}

.codics-factory-login-input input {
  color: #FFF !important;
  background-color: rgba(255, 255, 255, .25) !important;
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, .18);
}

.codics-factory-login-input .el-input__icon{
  color: #333 !important;
}

.codics-factory-login-input input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, .38) !important;
}

.codics-factory-login-input input::-moz-input-placeholder {
  color: #333 !important;
}

.codics-factory-login-input input::-ms-input-placeholder {
  color: #333 !important;
}

@media (max-width:1030px) {
  .codics-factory-login-carousel-box {
    display: none;
  }
}
</style>