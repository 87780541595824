<template>
    <div class="codics-simple-top-box-body">
        <div class="codics-simple-top-box-line">
            <div class="codics-simple-top-box-line-calendar">
                <div class="codics-simple-top-box-line-calendar-card">
                    <div class="codics-simple-top-box-line-calendar-view">
                        <div class="codics-simple-top-box-line-calendar-view-day">{{ pickDay }}</div>
                        <div class="iconfont codics-simple-top-box-line-calendar-view-month-icon codics-simple-top-box-line-calendar-view-month-last"
                            @click="pickPre(currentYear, currentMonth)" style="left: 0px;z-index: 1;">
                            &#xe842;</div>
                        <div class="codics-simple-top-box-line-calendar-view-pick-txt">{{ currentYear }} - {{
                            currentMonth
                            }}</div>
                        <div class="iconfont codics-simple-top-box-line-calendar-view-month-icon codics-simple-top-box-line-calendar-view-month-next"
                            @click="pickNext(currentYear, currentMonth)" style="right: 0px;">
                            &#xe844;</div>
                        <div class="codics-simple-top-box-line-calendar-view-tip-txt"
                            v-for="(item, index) in ['一', '二', '三', '四', '五', '六', '日']"
                            :style="{ 'color': index < 5 ? '#333' : '#FF0000' }" :key="index">{{ item }}</div>
                        <div class="codics-simple-top-box-line-calendar-view-num-txt" v-for="(item, index) in days"
                            @click="pick(item.day)"
                            :style="{ 'color': (item.day.getMonth() + 1 != currentMonth) ? '#BBB' : (item.day.getFullYear() == new Date().getFullYear() && item.day.getMonth() == new Date().getMonth() && item.day.getDate() == new Date().getDate() ? '#789AF7' : '#333') }"
                            :key="index">{{ item.day.getDate() }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            currentDay: 1,
            currentMonth: 1,
            currentYear: 1970,
            currentWeek: 1,
            days: [],
            pickDay: null,
            currentMemo: [
                '测试'
            ],
            memo_list: ["2018-05-11", "2018-05-31"]//时间格式必须为xxxx-xx-xx;
        }
    },
    mounted() {
        this.initCalendar(null);
    },
    methods: {
        initCalendar: function (cur) {
            if (cur) {
                var date = new Date(cur);
            } else {
                var date = new Date();
                this.pickDay = date.getDate();
            }
            this.currentYear = date.getFullYear();
            this.currentMonth = date.getMonth() + 1;
            var str = this.formatDate(this.currentYear, this.currentMonth, 1);
            this.currentWeek = new Date(str).getDay() == 0 ? 7 : new Date(str).getDay();

            this.days.length = 0;//初始化数组
            for (var i = this.currentWeek - 1; i >= 0; i--) {
                var d = new Date(str);
                var memo_type = false;
                d.setDate(d.getDate() - i);
                for (var j = 0; j < this.memo_list.length; j++) {
                    var memoTime = new Date(this.memo_list[j]).getTime();
                    if (d.getTime() == memoTime) {
                        memo_type = true;
                    }
                }
                this.days.push({ day: d, memo_type: memo_type });
            }
            for (var i = 1; i <= 35 - this.currentWeek; i++) {
                var d = new Date(str);
                d.setDate(d.getDate() + i);
                var memo_type = false;
                for (var j = 0; j < this.memo_list.length; j++) {
                    var memoTime = new Date(this.memo_list[j]).getTime()
                    if (d.getTime() == memoTime) {
                        memo_type = true;
                    }
                }
                this.days.push({ day: d, memo_type: memo_type });
            }
        },
        pick: function (date) {
            this.pickDay = date.getDate();
            this.initCalendar(this.formatDate(date.getFullYear(), date.getMonth() + 1, 1));
            var curPickDay = this.formatDate(date.getFullYear(), date.getMonth() + 1, date.getDate())
            // 数据发送请求拿回即可，这里我就写死了,随便写两个样例
            if (curPickDay == "2018-05-11") {
                this.currentMemo = [{
                    cont: "setDate() 方法用于设置一个月的某一天。该方法什么都不返回"
                }, {
                    cont: "getTime() 方法可返回距 1970 年 1 月 1 日之间的毫秒数。"
                }]
            } else if (curPickDay == "2018-05-31") {
                this.currentMemo = [{
                    cont: "getDay() 方法可返回表示星期的某一天的数字。返回值是 0（周日） 到 6（周六） 之间的一个整数"
                }, {
                    cont: "setDate(0); 上月最后一天；setDate(-1); 上月倒数第二天；setDate(dx) 参数dx为 上月最后一天的前后dx天"
                }]
            } else {
                this.currentMemo = null;
            }
        },
        pickPre: function (year, month) {
            // setDate(0); 上月最后一天
            // setDate(-1); 上月倒数第二天
            // setDate(dx) 参数dx为 上月最后一天的前后dx天
            var d = new Date(this.formatDate(year, month, 1));
            d.setDate(0);
            this.initCalendar(this.formatDate(d.getFullYear(), d.getMonth() + 1, 1));
        },
        pickNext: function (year, month) {
            var d = new Date(this.formatDate(year, month, 1));
            d.setDate(35);
            this.initCalendar(this.formatDate(d.getFullYear(), d.getMonth() + 1, 1));
        },
        // 返回 类似 2016-01-02 格式的字符串
        formatDate: function (year, month, day) {
            var y = year;
            var m = month;
            if (m < 10) m = "0" + m;
            var d = day;
            if (d < 10) d = "0" + d;
            return y + "-" + m + "-" + d
        }
    }
}
</script>

<style>
.codics-simple-top-box-body {
    height: 100%;
    width: 100%;
    position: relative;
    float: left;
}

.codics-simple-top-box-line {
    height: 440px;
    width: 100%;
    position: relative;
    float: left;
    box-sizing: border-box;
    padding: 7.5px;
}

.codics-simple-top-box-line-calendar {
    height: 100%;
    width: 650px;
    position: relative;
    float: left;
    box-sizing: border-box;
    padding: 7.5px;
}

.codics-simple-top-box-line-calendar-card {
    height: 100%;
    width: 100%;
    position: relative;
    float: left;
    box-sizing: border-box;
    background: #FFF;
    backdrop-filter: blur(1px);
    border-radius: 8px;
    border: 1px solid #EEE;
}

.codics-simple-top-box-line-calendar-view {
    height: 100%;
    width: 350px;
    position: relative;
    float: left;
    border-right: 1px solid #EEE;
    border-radius: 8px;
    backdrop-filter: blur(1px);
    box-sizing: border-box;
    padding: 0 15px;
}

.codics-simple-top-box-line-calendar-view-day {
    height: 65px;
    width: 100%;
    position: relative;
    float: left;
    line-height: 85px;
    font-size: 45px;
    text-align: center;
    color: #789AF7;
}

.codics-simple-top-box-line-calendar-view-day::after {
    content: '日';
    height: 100%;
    width: 100%;
    position: absolute;
    margin: auto;
    top: 22px;
    left: 33px;
    right: 0;
    bottom: 0;
    font-size: 11px;
    color: #333;
}

.codics-simple-top-box-line-calendar-view-month-icon {
    height: 50px;
    width: 50px;
    position: relative;
    float: left;
    line-height: 50px;
    text-align: center;
    color: #333;
    font-size: 18px;
    user-select: none;
    -webkit-user-drag: none;
    cursor: pointer;
}

.codics-simple-top-box-line-calendar-view-pick-txt {
    height: 50px;
    width: calc(100% - 100px);
    position: relative;
    float: left;
    line-height: 50px;
    text-align: center;
    font-size: 15px;
    color: #333;
}

.codics-simple-top-box-line-calendar-view-tip-txt {
    height: 50px;
    width: 14.285%;
    position: relative;
    float: left;
    line-height: 50px;
    text-align: center;
    font-size: 14px;
    user-select: none;
    -webkit-user-drag: none;
}

.codics-simple-top-box-line-calendar-view-num-txt {
    height: 45px;
    width: 14.285%;
    position: relative;
    float: left;
    line-height: 44px;
    text-align: center;
    font-size: 14px;
    user-select: none;
    -webkit-user-drag: none;
    cursor: pointer;
}
</style>