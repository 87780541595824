<template>
  <div id="app">
    <el-image ref="wallpaper" class="codics-simple-home-back-img" :src="nowWallpaper" fit="cover"
      @load="loadWallPaperSuccess"
      :style="{ 'transform': backState ? 'scale(1.1)' : 'scale(1)', 'filter': backState ? 'blur(7px)' : 'unset', 'transition': backState ? 'ease 0.3s !important' : 'all 0.3s ease 0s', 'visibility': wallState ? 'visible' : 'hidden', 'opacity': wallState ? '1' : '0' }"></el-image>
    <el-image ref="wallpaper" class="codics-simple-home-back-img" :src="nowWallpaper2" fit="cover"
      @load="loadWallPaperSuccess"
      :style="{ 'transform': backState ? 'scale(1.1)' : 'scale(1)', 'filter': backState ? 'blur(7px)' : 'unset', 'transition': backState ? 'ease 0.3s !important' : 'all 0.3s ease 0s', 'visibility': !wallState && nowWallpaper2 != '' ? 'visible' : 'hidden', 'opacity': !wallState && nowWallpaper2 != '' ? '1' : '0' }"></el-image>
    <transition name="fade">
      <router-view ref="childRef" @change-back-state="changeBackState"
        @change-wall-paper="changeWallPaper"></router-view>
    </transition>
  </div>
</template>

<script>
import Home from '@/pages/Home';
import Login from '@/pages/Login';
import axios from 'axios';
import { randomWallpaper } from './api';
import { getCookie, setCookie, getCookieExp, delCookie } from './utils/mycookie';
import { randomString, jumpTo, getRandomInt } from '@/utils/myutils';
import { hex_md5 } from '@/utils/md5';
// 初始化设备唯一识别码
if (getCookie('x-myweb-key') == null) {
  //设置设备唯一识别码
  setCookie('x-myweb-key', hex_md5(new Date().valueOf().toString() + randomString(50)));

}
export default {
  name: 'App',
  data() {
    return {
      nowWallpaper: '',
      nowWallpaper2: '',
      backState: false,
      wallState: false,
      wallpaperData: []
    }
  },
  mounted() {

    this.randomWallpaperFuc();

  },
  methods: {
    changeBackState(state) {

      this.backState = state;

    },
    loadWallPaperSuccess(e) {

      this.wallState = !this.wallState;

    },
    changeWallPaper() {

      if (!this.wallState) {

        this.nowWallpaper = 'https://cn.bing.com/' + this.wallpaperData[getRandomInt(0, this.wallpaperData.length - 1)].url;

      } else {

        this.nowWallpaper2 = 'https://cn.bing.com/' + this.wallpaperData[getRandomInt(0, this.wallpaperData.length - 1)].url;

      }

    },
    async randomWallpaperFuc() {

      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      let data = await randomWallpaper();

      loading.close();

      if (data.code == 200) {

        this.wallpaperData = data.data;

        this.nowWallpaper = 'https://cn.bing.com/' + data.data[getRandomInt(0, data.data.length - 1)].url;

        if (getCookie("simple-search-wallpaper") != null) {

          this.nowWallpaper = getCookie("simple-search-wallpaper");

        }

      } else {

        this.$message.error(data.message);

      }

    }
  },
  components: {
    Home,
    Login
  },
  watch: {
    nowWallpaper(newVal, oldVal) {
      window.nnnWallpaper = newVal;
    },
    nowWallpaper2(newVal, oldVal) {
      window.nnnWallpaper = newVal;
    }
  }
}
</script>

<style>
/* APP主体部分 */
#app {
  height: 100%;
  width: 100%;
  position: relative;
  padding: 0;
  margin: 0;
  background: #FFF;
  box-sizing: border-box;
  overflow: hidden;
}

/* 主页背景 */
.codics-simple-home-back-img {
  height: 100%;
  width: 100%;
  position: absolute !important;
  left: 0;
  top: 0;
  overflow: hidden;
  user-select: none;
}

.codics-simple-home-back-img .el-image__inner {
  -webkit-user-drag: none !important;
  user-select: none !important;
  -moz-user-select: none !important;
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
}

/* 定义进入和离开的过渡效果 */
.fade-enter-active,
.fade-leave-active {
  transition: .5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
