<template>
    <transition name="wdialog">
        <div v-show="value" class="codics-sjnr-wdialog-body" @click="changeShow">
            <div class="codics-sjnr-wdialog-dialog" :style="{ 'height': height, 'max-width': width }" @click="ceshi">
                <div class="codics-sjnr-wdialog-dialog-top" v-show="title != null">{{ title }}
                    <span class="codics-sjnr-wdialog-dialog-top-icon iconfont" @click="changeShow"></span>
                </div>
                <slot></slot>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: null
        },
        height: {
            type: String,
            default: 'fit-content'
        },
        width: {
            type: String,
            default: '40%'
        },
        value: {
            type: Boolean,
            default:false
        }
    },
    data() {
        return {
        }
    },
    methods: {
        changeShow(e) {
            this.$emit('input', !this.value);
            e.stopPropagation();
            return false;
        },
        ceshi(e) { 
            e.stopPropagation();
            return;
        }
    }
}
</script>

<style>
/* 弹窗体 */
.codics-sjnr-wdialog-body {
    height: 100%;
    width: 100%;
    transition: .3s;
    position: fixed;
    z-index: 11;
    top: 0;
    left: 0;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
}

.codics-sjnr-wdialog-dialog {
    width: calc(100% - 40px) !important;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(13px);
    -webkit-backdrop-filter: blur(13px);
    border: 1px solid rgba(255, 255, 255, .18);
    border-radius: 5px;
    pointer-events: all;
}

.codics-sjnr-wdialog-dialog-top {
    height: 50px;
    width: 100%;
    position: relative;
    float: left;
    box-sizing: border-box;
    line-height: 50px;
    font-size: 14px;
    padding-left: 15px;
    color: #FFF;
    user-select: none;
}

.codics-sjnr-wdialog-dialog-top-icon {
    height: 50px;
    width: 50px;
    position: relative;
    float: right;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
}

.codics-sjnr-wdialog-dialog-top-icon::after {
    content: '\e84a';
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transition: .3s;
    opacity: 0;
    font-size: 10px;
}

.codics-sjnr-wdialog-dialog-top-icon::before {
    content: '';
    height: 14px;
    width: 14px;
    position: absolute;
    margin: auto;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(240, 64, 41, 0.692);
    backdrop-filter: blur(3px);
    border-radius: 9.5px;
}

.codics-sjnr-wdialog-dialog-top-icon:hover::after {
    opacity: 1 !important;
}

/* 定义进入和离开的过渡效果 */
.wdialog-enter-active,
.wdialog-leave-active {
    transition: .3s;
}

.wdialog-enter,
.wdialog-leave-to {
    opacity: 0;
}
</style>